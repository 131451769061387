import axios from "axios"
import {blobToBase64} from "./blob_helper";

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function getBlob(url, config = {}) {
  const response = await fetch(url, {
    ...config,
    method: 'GET',
  })

  if (!response.ok) {
    await HttpResponseException.handle(response)
  }

  return response.status === 204 ? null : await response.blob()
}

export async function getBlobAsBase64(url, config = {}) {
  const blob = await getBlob(url, config);

  if (!blob?.text) {
    return null;
  }

  return await blobToBase64(blob);
}


export async function get(url, config = {}) {
  const response = await fetch(url, {
    ...config,
    method: 'GET',
  })

  if (!response.ok) {
    await HttpResponseException.handle(response)
  }

  return response.status === 204 ? null : await response.json()
}

export async function post(url, data, config = {}) {
  const errorMessage = config.errorMessage;

  delete config.errorMessage;

  const response = await fetch(url, {
    ...config,
    method: 'POST',
    headers: {
      ...(config.headers || {}),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    await HttpResponseException.handle(response, errorMessage)
  }

  return response.status === 204 ? null : await response.json()
}

export async function put(url, data, config = {}) {
  const errorMessage = config.errorMessage;

  delete config.errorMessage;

  const response = await fetch(url, {
    ...config,
    method: 'PUT',
    headers: {
      ...(config.headers || {}),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    await HttpResponseException.handle(response, errorMessage)
  }

  return response.status === 204 ? null : await response.json()
}

export async function patch(url, data, config = {}) {
  const errorMessage = config.errorMessage;

  delete config.errorMessage;

  const response = await fetch(url, {
    ...config,
    method: 'PATCH',
    headers: {
      ...(config.headers || {}),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    await HttpResponseException.handle(response, errorMessage)
  }

  return response.status === 204 ? null : await response.json()
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

class HttpResponseException extends Error {
  constructor(response, message) {
    super(message);

    this.response = response;
  }
  static async handle(response, defaultMessage) {
    if (response.status === 401) {
      const body = await response.json();

      if (body?.message === 'Expired JWT Token') {
        throw new HttpResponseException(response, 'Error.Api.ExpiredJwtToken');
      }
    }
    
    if (!defaultMessage) {
      if (response.status >= 400 && response < 500) {
        defaultMessage = 'Error.Api.ClientError'
      } else {
        defaultMessage = 'Error.Api.ServerError'
      }
    }

    throw new HttpResponseException(response, defaultMessage);
  }
}
