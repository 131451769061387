import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import {Alert, Button} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    selectStripeSettings, selectStripeSettingsError, selectStripeSettingsLoading,
    selectStripeVerificationSessionError,
    selectStripeVerificationSessionInProgress, selectStripeVerificationSessionLoading,
    selectStripeVerificationSessionSecret
} from "../../store/stripe/selectors";
import {createStripeVerificationSession, getSettings, stripeVerificationSessionEnd} from "../../store/stripe/actions";
import Loading from "../Loading";
import {loadStripe} from "@stripe/stripe-js";

function Wrapper(props) {
    const settings = useSelector(selectStripeSettings);
    const dispatch = useDispatch();
    const loading = useSelector(selectStripeSettingsLoading);
    const error = useSelector(selectStripeSettingsError);

    useEffect(() => {
        if (!settings) {
            dispatch(getSettings());
        }
    }, [settings])

    if (!settings || loading) {
        return <Loading />
    } else if (error) {
        return <Alert color="danger">{error}</Alert>;
    }


    return (
        <Elements stripe={loadStripe(settings.publishable_key)}>
            <VerificationSession {...props} />
        </Elements>
    );
}

function VerificationSession(props) {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const loading = useSelector(selectStripeVerificationSessionLoading);
    const error = useSelector(selectStripeVerificationSessionError);
    const inProgress = useSelector(selectStripeVerificationSessionInProgress);
    const secret = useSelector(selectStripeVerificationSessionSecret);

    useEffect(() => {
        if (inProgress === true && secret) {
            stripe.verifyIdentity(secret).then(result => {
                if (result.error) {
                    alert('cheh: ' + result.error);
                    console.log(result)
                } else {
                    console.log(result);
                }

                dispatch(stripeVerificationSessionEnd('processing'))
            })
        }
    }, [inProgress, secret, stripe])

    return (
        <>
            <h2>{props.t('KYC.None.ValidateYourIdentity')}</h2>

            {Boolean(error) && (
                <Alert color="danger">{error}</Alert>
            )}

            {!loading && !Boolean(error) && (
                <Button type="button" onClick={() => {dispatch(createStripeVerificationSession())}}>{props.t('Start')}</Button>
            )}

            {loading && (
                <Loading />
            )}
        </>
    )
}

VerificationSession.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Wrapper)
