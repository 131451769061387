import {call, put, select, takeEvery} from "redux-saga/effects"

// Crypto Redux States
import { GET_PROJECTS, GET_PROJECT_DETAIL, ADD_NEW_PROJECT, DELETE_PROJECT, UPDATE_PROJECT } from "./actionTypes"
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import { postSearchWallets, getProjectsDetails, postWallet, patchWallet, deleteWallet } from "helpers/fakebackend_helper"
import {selectAccessToken} from "../auth/login/selectors";

function* fetchProjects() {
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(postSearchWallets({headers: {Authorization: `Bearer ${accessToken}`}}))
    yield put(getProjectsSuccess(response))
  } catch (error) {
    yield put(getProjectsFail(error))
  }
}

function* fetchProjectDetail({ id }) {
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(getProjectsDetails({headers: {Authorization: `Bearer ${accessToken}`}}), id)
    yield put(getProjectDetailSuccess(response))
  } catch (error) {
    yield put(getProjectDetailFail(error))
  }
}

function* onUpdateProject({ payload: {id, project} }) {
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(patchWallet({headers: {Authorization: `Bearer ${accessToken}`}}), id, project)
    yield put(updateProjectSuccess(response))
  } catch (error) {
    yield put(updateProjectFail(error))
  }
}

function* onDeleteProject({ payload: { id } }) {
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(deleteWallet({headers: {Authorization: `Bearer ${accessToken}`}}), project)
    yield put(deleteProjectSuccess(response))
  } catch (error) {
    yield put(deleteProjectFail(error))
  }
}

function* onAddNewProject({ payload: project }) {
  const accessToken = yield select(selectAccessToken);

  try {
    const response = yield call(postWallet, project)
    yield put(addProjectSuccess(response))
  } catch (error) {

    yield put(addProjectFail(error))
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects)
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail)
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject)
  yield takeEvery(UPDATE_PROJECT, onUpdateProject)
  yield takeEvery(DELETE_PROJECT, onDeleteProject)
}

export default projectsSaga
