import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_FORGOTTEN_PASSWORD } from "./actionTypes"
import {changeForgottenPasswordSuccess, changeForgottenPasswordError} from "./actions"

//Include Both Helper File with needed methods
import {changeForgottenPassword} from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* doChangeForgottenPassword({ payload: { values, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      changeForgottenPasswordError('Firebase is not supported yet')
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      yield call(changeForgottenPassword, values)

      yield put(changeForgottenPasswordSuccess())
      history.push('/')
    } else {
      changeForgottenPasswordError('Fakebackend is not supported yet')
    }
  } catch (error) {
    yield put(changeForgottenPasswordError(error))
  }
}

export function* watchChangeForgottenPassword() {
  yield takeEvery(CHANGE_FORGOTTEN_PASSWORD, doChangeForgottenPassword)
}

function* changeForgottenPasswordSaga() {
  yield all([fork(watchChangeForgottenPassword)])
}

export default changeForgottenPasswordSaga
