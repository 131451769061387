import {withTranslation} from "react-i18next";
import {Alert, Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormik} from "formik";
import * as Yup from "yup";
import {changeContact, changeContactFinished, changeContactResetSpecificErrors} from "../../store/auth/profile/actions";
import {useDispatch, useSelector} from "react-redux";
import {selectMe} from "../../store/auth/login/selectors";
import {
    selectChangeContactError, selectChangeContactSpecificErrors,
    selectProfileEdited,
} from "../../store/auth/profile/selectors";

function ContactDetails(props) {
    const dispatch = useDispatch()
    const me = useSelector(selectMe)
    const contactEdited = useSelector(selectProfileEdited)
    const error = useSelector(selectChangeContactError)
    const specificErrors = useSelector(selectChangeContactSpecificErrors)
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: me?.email || '',
            previous_password: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(props.t("Form.EnterEmpty.Email")),
            previous_password: Yup.string().required(props.t("Form.EnterEmpty.PreviousPassword")),
            password: Yup.string(),
        }),
        onSubmit: (values) => {
            dispatch(changeContact(values))
        }
    })

    useEffect(() => {
        if (contactEdited) {
            const timeout = setTimeout(() => {
                dispatch(changeContactFinished())
            }, 5000);

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [contactEdited, dispatch])

    return <>
        <h4 className="card-title mb-4">{props.t('Profile.ContactDetails')}</h4>
        <Card>
            <CardBody>
                {contactEdited && (
                    <Alert color="success">
                        {props.t('Contact.EditSuccess')}
                    </Alert>
                )}
                {Boolean(error && !specificErrors?.length) && (
                    <Alert color="danger">
                        {props.t('Contact.EditError')}
                    </Alert>
                )}
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className="form-group">
                        <Label className="form-label">{props.t('Email')}</Label>
                        <Input
                            name="email"
                            className="form-control"
                            placeholder={props.t('Form.Enter.Email')}
                            type="email"
                            onChange={event => {
                                validation.handleChange(event)
                                dispatch(changeContactResetSpecificErrors())
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={Boolean(validation.touched.email && validation.errors.email) || specificErrors?.includes('username_already_taken')}
                        />
                        {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                        {specificErrors?.includes('username_already_taken') && (
                            <FormFeedback type="invalid">{props.t('Contact.Username.AlreadyTakenError')}</FormFeedback>
                        )}
                    </div>
                    <div className="form-group">
                        <Label className="form-label">{props.t('Contact.PreviousPassword')}</Label>
                        <Input
                            name="previous_password"
                            className="form-control"
                            placeholder={props.t('Form.Enter.PreviousPassword')}
                            type="password"
                            onChange={event => {
                                validation.handleChange(event)
                                dispatch(changeContactResetSpecificErrors())
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.previous_password || ""}
                            invalid={Boolean(validation.touched.previous_password && validation.errors.previous_password)|| specificErrors?.includes('invalid_password')}
                        />

                        {validation.touched.previous_password && validation.errors.previous_password ? (
                            <FormFeedback type="invalid">{validation.errors.previous_password}</FormFeedback>
                        ) : null}

                        {specificErrors?.includes('invalid_password') && (
                            <FormFeedback type="invalid">{props.t('Contact.Password.WrongPasswordError')}</FormFeedback>
                        )}
                    </div>
                    <div className="form-group">
                        <Label className="form-label">{props.t('NewPassword')}</Label>
                        <Input
                            name="password"
                            className="form-control"
                            placeholder={props.t('Form.Enter.NewPassword')}
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={Boolean(validation.touched.password && validation.errors.password)}
                        />
                        {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                    </div>
                    <div className="text-center mt-4">
                        <Button type="submit" color="danger">
                            {props.t('Update')}
                        </Button>
                    </div>
                </Form>
            </CardBody>
        </Card>
    </>
}

ContactDetails.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(ContactDetails);
