import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar.jpg"

// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {selectMe} from "../../store/auth/login/selectors";

import "./user-profile.scss";
import ContactDetails from "./ContactDetails";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import AddressForm from "./AddressForm";

const Profile = props => {
  const me = useSelector(selectMe)

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Hesiode</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Hesiode" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt="Avatar"
                        className="avatar-md rounded-circle img-thumbnail user-profile-avatar"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{me.first_name} {me.last_name}</h5>
                        <p className="mb-1">{props.t('Email')}: {me.email}</p>
                        <p className="mb-0">{props.t('Id')}: {me.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <ContactDetails />
              <AddressForm />
            </Col>
            <Col xs={12} sm={6}>
              <TwoFactorAuthentication />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Profile.propTypes = {
  t: PropTypes.func,
};

export default withRouter(withTranslation()(Profile));
