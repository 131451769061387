import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from "reactstrap";
import {withTranslation} from "react-i18next";

function NoWallet(props) {
    return (
        <Alert color="info">
            <i className="bx bx-info-circle mx-2" />
            {props.t('Projects.NoProject')}
        </Alert>
    )
}

NoWallet.propTypes = {
    t: PropTypes.func,
};

export default withTranslation()(NoWallet);
