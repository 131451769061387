import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {Route, Redirect, useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {selectAccessToken, selectMe} from "../store/auth/login/selectors";
import {getMe} from "../store/auth/login/actions";
import Loading from "../pages/Loading";

function Authmiddleware({
                            component: Component,
                            layout: Layout,
                            isAuthProtected,
                            ...rest
                        }) {
    const accessToken = useSelector(selectAccessToken)
    const me = useSelector(selectMe)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (accessToken && !me?.id) {
            const destination = location.pathname + location.search + location.hash
            dispatch(getMe(history, destination));
        }
    }, [accessToken, me, dispatch]);

    if (accessToken && !me) {
        return <Loading />
    }

    return <Route
        {...rest}
        render={props => {
            if (isAuthProtected && !me?.id) {
                return (
                    <Redirect
                        to={{pathname: "/login", state: {from: props.location}}}
                    />
                )
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }}
    />;
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.func,
  location: PropTypes.object,
  layout: PropTypes.func,
}

export default Authmiddleware;
