import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectProducts} from "../../store/products/selectors";
import {getProducts} from "../../store/products/actions";


function ProductSelect(props) {
    const dispatch = useDispatch()
    const products = useSelector(selectProducts)

    useEffect(() => {
        console.log(products);
        if (null === products) {
            dispatch(getProducts())
        }
    }, [products, dispatch])

    return (
        <select className={props.className}>
            {products?.map?.(product => (
                <option value={product.id} key={product.id}>
                    {product.name}
                </option>
            ))}
        </select>
    )
}

ProductSelect.propTypes = {
    t: PropTypes.func,
    className: PropTypes.func,
}

export default withTranslation()(ProductSelect);
