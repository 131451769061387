import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_FAIL,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHOD_DETAIL,
  ADD_NEW_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD_DETAIL_FAIL,
  GET_PAYMENT_METHOD_DETAIL_SUCCESS,
} from "./actionTypes"

export const getPaymentMethods = () => ({
  type: GET_PAYMENT_METHODS,
})

export const getPaymentMethodsSuccess = paginator => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  payload: paginator,
})

export const addNewPaymentMethod = paymentMethod => ({
  type: ADD_NEW_PAYMENT_METHOD,
  payload: paymentMethod,
})

export const addPaymentMethodSuccess = paymentMethod => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
})

export const addPaymentMethodFail = error => ({
  type: ADD_PAYMENT_METHOD_FAIL,
  payload: error,
})

export const updatePaymentMethod = paymentMethod => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: paymentMethod,
})

export const updatePaymentMethodSuccess = paymentMethod => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
})

export const updatePaymentMethodFail = error => ({
  type: UPDATE_PAYMENT_METHOD_FAIL,
  payload: error,
})

export const deletePaymentMethod = paymentMethod => ({
  type: DELETE_PAYMENT_METHOD,
  payload: paymentMethod,
})

export const deletePaymentMethodSuccess = paymentMethod => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
})

export const deletePaymentMethodFail = error => ({
  type: DELETE_PAYMENT_METHOD_FAIL,
  payload: error,
})

export const getPaymentMethodsFail = error => ({
  type: GET_PAYMENT_METHODS_FAIL,
  payload: error,
})

export const getPaymentMethodDetail = paymentMethodId => ({
  type: GET_PAYMENT_METHOD_DETAIL,
  paymentMethodId,
})

export const getPaymentMethodDetailSuccess = paymentMethodDetails => ({
  type: GET_PAYMENT_METHOD_DETAIL_SUCCESS,
  payload: paymentMethodDetails,
})

export const getPaymentMethodDetailFail = error => ({
  type: GET_PAYMENT_METHOD_DETAIL_FAIL,
  payload: error,
})
