export const selectProfileEdited = state => state.Profile.edited
export const selectChangeContactError = state => state.Profile.changeContactError
export const selectChangeContactSpecificErrors = state => state.Profile.changeContactSpecificErrors
export const selectChangeAddressEdited = state => state.Profile.addressEdited
export const selectChangeAddressLoading = state => state.Profile.addressLoading
export const selectChangeAddressError = state => state.Profile.addressError
export const selectTotpQrCode = state => state.Profile.totpQrcode
export const selectTotpQrCodeLoading = state => state.Profile.totpQrcodeLoading
export const selectTotpQrCodeError = state => state.Profile.totpQrcodeError
export const selectTotpEnableLoading = state => state.Profile.totpEnableLoading
export const selectTotpEnableError = state => state.Profile.totpEnableError
export const selectTotpDisableLoading = state => state.Profile.totpDisableLoading
export const selectTotpDisableError = state => state.Profile.totpDisableError
