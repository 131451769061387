import {
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL, GET_PRODUCTS,
} from "./actionTypes"

const INIT_STATE = {
  products: null,
  total: 0,
  offset: 0,
  limit: 0,
  loading: false,
  productDetail: null,
  error: null,
}

const products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.items,
        offset: action.payload.offset,
        total: action.payload.total,
        limit: action.payload.limit,
        loading: false,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
      }

    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.payload,
      }

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map(product =>
          product.id.toString() === action.payload.id.toString()
            ? { product, ...action.payload }
            : product
        ),
      }

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default products
