import {
    GET_SETTINGS,
    GET_SETTINGS_FAIL,
    GET_SETTINGS_SUCCESS,
    CREATE_VERIFICATION_SESSION,
    CREATE_VERIFICATION_SESSION_SUCCESS,
    CREATE_VERIFICATION_SESSION_FAIL,
    VERIFICATION_SESSION_END,
} from "./actionTypes";

export const getSettings = () => ({
    type: GET_SETTINGS,
})

export const getStripeSettingsSuccess = (settings) => ({
    type: GET_SETTINGS_SUCCESS,
    settings,
})

export const getStripeSettingsFail = (error) => ({
    type: GET_SETTINGS_FAIL,
    error,
})

export const createStripeVerificationSession = () => ({
    type: CREATE_VERIFICATION_SESSION,
})

export const createStripeVerificationSessionSuccess = (secret) => ({
    type: CREATE_VERIFICATION_SESSION_SUCCESS,
    secret,
})

export const createStripeVerificationSessionFail = (error) => ({
    type: CREATE_VERIFICATION_SESSION_FAIL,
    error,
})

export const stripeVerificationSessionEnd = (status) => ({
    type: VERIFICATION_SESSION_END,
    status,
})
