import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import {Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Spinner} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import LoginFormHeader from "./LoginFormHeader";

// import images
import CopyrightNotice from "../../components/CopyrightNotice";
import {withTranslation} from "react-i18next";
import {changeForgottenPassword} from "../../store/auth/changeforgetpwd/actions";
import {
  selectChangeForgottenPasswordError,
  selectChangeForgottenPasswordLoading,
  selectChangeForgottenPasswordSuccess
} from "../../store/auth/changeforgetpwd/selectors";

const ChangeForgottenPassword = props => {
  const dispatch = useDispatch();

  const success = useSelector(selectChangeForgottenPasswordSuccess);
  const loading = useSelector(selectChangeForgottenPasswordLoading);
  const error = useSelector(selectChangeForgottenPasswordError);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(props.t('Form.EnterEmpty.NewPassword')),
      passwordConfirmation: Yup.string().required(props.t('Form.EnterEmpty.PasswordConfirmation')),
    }),
    onSubmit: (values) => {
      const searchParams = new URLSearchParams(location.search);
      const user = searchParams.get('user');
      const signature = searchParams.get('signature');

      dispatch(changeForgottenPassword(
          {password: values.password, user, signature},
          props.history
      ));
    }
  });

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forgot Password | Hesiode
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <LoginFormHeader
                  title={props.t('ChangeForgottenPassword.Title')}
                  subtitle={props.t('ChangeForgottenPassword.Subtitle')}
                />
                <CardBody className="pt-3">
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.t(error)}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{props.t('NewPassword')}</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder={props.t('Form.Enter.NewPassword')}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t('PasswordConfirmation')}</Label>
                        <Input
                          name="passwordConfirmation"
                          className="form-control"
                          placeholder={props.t('Form.Enter.PasswordConfirmation')}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordConfirmation || ""}
                          invalid={
                            Boolean(
                                validation.touched.passwordConfirmation &&
                                validation.errors.passwordConfirmation &&
                                validation.values.password !== validation.values.passwordConfirmation
                            )
                          }
                        />
                        {validation.touched.passwordConfirmation && validation.errors.passwordConfirmation ? (
                          <FormFeedback type="invalid">{validation.errors.passwordConfirmation}</FormFeedback>
                        ) : null}
                        {validation.values.password !== validation.values.passwordConfirmation  ? (
                          <FormFeedback type="invalid">{props.t('Error.Form.PasswordMustMatchConfirmation')}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            disabled={loading || success}
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {success && <i className={"fa fa-check"} />}
                            {!success && loading && <Spinner size="sm" />}
                            {!success && !loading && props.t('Send')}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  <Link to="login" className="font-weight-medium text-primary">
                    {props.t('ReturnToLogin')}
                  </Link>
                </p>
                <CopyrightNotice />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ChangeForgottenPassword.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(withTranslation()(ChangeForgottenPassword));
