import {
  GET_PAYMENT_METHODS_FAIL,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHOD_DETAIL_FAIL,
  GET_PAYMENT_METHOD_DETAIL_SUCCESS,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAIL, GET_PAYMENT_METHODS,
} from "./actionTypes"

const INIT_STATE = {
  paymentMethods: [],
  total: 0,
  offset: 0,
  limit: 0,
  loading: false,
  paymentMethodDetail: null,
  error: null,
}

const paymentMethods = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload.items,
        offset: action.payload.offset,
        total: action.payload.total,
        limit: action.payload.limit,
        loading: false,
      }

    case GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.payload],
      }

    case ADD_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PAYMENT_METHOD_DETAIL_SUCCESS:
      return {
        ...state,
        paymentMethodDetail: action.payload,
      }

    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: state.paymentMethods.map(paymentMethod =>
          paymentMethod.id.toString() === action.payload.id.toString()
            ? { paymentMethod, ...action.payload }
            : paymentMethod
        ),
      }

    case UPDATE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          paymentMethod => paymentMethod.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_PAYMENT_METHOD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default paymentMethods
