import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";

import {Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import LoginFormHeader from "./LoginFormHeader";

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";
import {withTranslation} from "react-i18next";
import CopyrightNotice from "../../components/CopyrightNotice";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import {selectChangeForgottenPasswordSuccess} from "../../store/auth/changeforgetpwd/selectors";
import TotpLoginModal from "./TotpLoginModal";

const Login = props => {
  const dispatch = useDispatch()
  const passwordChanged = useSelector(selectChangeForgottenPasswordSuccess)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t('Form.EnterEmpty.Email')),
      password: Yup.string().required(props.t('Form.EnterEmpty.Password')),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history));
  };

  return (
    <React.Fragment>
      <TotpLoginModal />
      <MetaTags>
        <title>{props.t('Login')} | Hesiode</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <LoginFormHeader />
                <CardBody className="pt-0">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <LanguageDropdown />
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                      {passwordChanged ? <Alert color="info">{props.t('Login.PasswordChanged')}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Email')}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={props.t('Form.Enter.Email')}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t('Password')}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={props.t('Form.Enter.Password')}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          name="rememberMe"
                          onChange={validation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {props.t('Login.RememberMe')}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ?
                              <Spinner size="sm" /> :
                              props.t('Login.LogIn')
                          }
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {props.t('Login.PasswordForgotten')}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t('Login.NoAccount')}
                  <br/>
                  <a href={process.env.REACT_APP_REGISTER_APP_URL} className="fw-medium text-primary">
                    {props.t('Login.Register')}
                  </a>
                </p>
                <CopyrightNotice/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(withTranslation()(Login));
