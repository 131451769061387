import {withTranslation} from "react-i18next";
import {Alert, Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useFormik} from "formik";
import * as Yup from "yup";
import {changeAddress, changeAddressFinished} from "../../store/auth/profile/actions";
import {useDispatch, useSelector} from "react-redux";
import {selectMe} from "../../store/auth/login/selectors";
import {
    selectChangeAddressEdited, selectChangeAddressError,
} from "../../store/auth/profile/selectors";

function AddressForm(props) {
    const dispatch = useDispatch()
    const me = useSelector(selectMe)
    const edited = useSelector(selectChangeAddressEdited)
    const error = useSelector(selectChangeAddressError)
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            line1: me?.address?.lines?.[0] || '',
            line2: me?.address?.lines?.[1] || '',
            line3: me?.address?.lines?.[2] || '',
            zip_code: me?.address?.zip_code || '',
            city: me?.address?.city || '',
            state: me?.address?.state || '',
            country: me?.address?.country || '',
        },
        validationSchema: Yup.object({
            line1: Yup.string().required(props.t("Form.EnterEmpty.Address.Line1")),
            line2: Yup.string(),
            line3: Yup.string(),
            zip_code: Yup.string().required(props.t("Form.EnterEmpty.Address.ZipCode")),
            city: Yup.string().required(props.t("Form.EnterEmpty.Address.City")),
            state: Yup.string().required(props.t("Form.EnterEmpty.Address.State")),
            country: Yup.string().required(props.t("Form.EnterEmpty.Address.Country")),
        }),
        onSubmit: (values) => {
            dispatch(changeAddress(values))
        }
    })

    useEffect(() => {
        if (edited) {
            const timeout = setTimeout(() => {
                dispatch(changeAddressFinished())
            }, 5000);

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [edited, dispatch])

    return <>
        <h4 className="card-title mb-4">{props.t('AddressForm.Title')}</h4>
        <Card>
            <CardBody>
                {edited && (
                    <Alert color="success">
                        {props.t('AddressForm.EditSuccess')}
                    </Alert>
                )}
                {Boolean(error) && (
                    <Alert color="danger">
                        {props.t('AddressForm.EditError')}
                    </Alert>
                )}
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className="form-group">
                        <Label className="form-label">{props.t('Address')}</Label>
                        <Input
                            name="line1"
                            className="form-control"
                            placeholder={props.t('Form.Enter.Address.Line1')}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.line1 || ""}
                            invalid={Boolean(validation.touched.line1 && validation.errors.line1)}
                        />
                        {validation.touched.line1 && validation.errors.line1 ? (
                            <FormFeedback type="invalid">{validation.errors.line1}</FormFeedback>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <Input
                            name="line2"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.line2 || ""}
                            invalid={Boolean(validation.touched.line2 && validation.errors.line2)}
                        />
                        {validation.touched.line2 && validation.errors.line2 ? (
                            <FormFeedback type="invalid">{validation.errors.line2}</FormFeedback>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <Input
                            name="line3"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.line3 || ""}
                            invalid={Boolean(validation.touched.line3 && validation.errors.line3)}
                        />
                        {validation.touched.line3 && validation.errors.line3 ? (
                            <FormFeedback type="invalid">{validation.errors.line3}</FormFeedback>
                        ) : null}
                    </div>
                    <Row>
                        <Col xs={6}>
                            <div className="form-group">
                                <Label className="form-label">{props.t('ZipCode')}</Label>
                                <Input
                                    name="zip_code"
                                    className="form-control"
                                    placeholder={props.t('Form.Enter.Address.ZipCode')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.zip_code || ""}
                                    invalid={Boolean(validation.touched.zip_code && validation.errors.zip_code)}
                                />
                                {validation.touched.zip_code && validation.errors.zip_code ? (
                                    <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="form-group">
                                <Label className="form-label">{props.t('City')}</Label>
                                <Input
                                    name="city"
                                    className="form-control"
                                    placeholder={props.t('Form.Enter.Address.City')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city || ""}
                                    invalid={Boolean(validation.touched.city && validation.errors.city)}
                                />
                                {validation.touched.city && validation.errors.city ? (
                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div className="form-group">
                                <Label className="form-label">{props.t('State')}</Label>
                                <Input
                                    name="state"
                                    className="form-control"
                                    placeholder={props.t('Form.Enter.Address.State')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.state || ""}
                                    invalid={Boolean(validation.touched.state && validation.errors.state)}
                                />
                                {validation.touched.state && validation.errors.state ? (
                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="form-group">
                                <Label className="form-label">{props.t('Country')}</Label>
                                <Input
                                    name="country"
                                    className="form-control"
                                    placeholder={props.t('Form.Enter.Address.Country')}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.country || ""}
                                    invalid={Boolean(validation.touched.country && validation.errors.country)}
                                />
                                {validation.touched.country && validation.errors.country ? (
                                    <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center mt-4">
                        <Button type="submit" color="danger">
                            {props.t('Update')}
                        </Button>
                    </div>
                </Form>
            </CardBody>
        </Card>
    </>
}

AddressForm.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(AddressForm);
