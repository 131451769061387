import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_ME,
  GET_ME_SUCCESS,
  GET_ME_ERROR,
  MISSING_TOTP_ERROR,
  TOTP_LOGIN,
  TOTP_LOGIN_SUCCESS,
  TOTP_LOGIN_ERROR,
} from "./actionTypes"

export const loginUser = (values, history) => {
  return {
    type: LOGIN_USER,
    payload: { values, history },
  }
}

export const loginSuccess = payload => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const totpLogin = (code, history) => {
  return {
    type: TOTP_LOGIN,
    payload: { code, history },
  }
}

export const totpLoginSuccess = () => {
  return {
    type: TOTP_LOGIN_SUCCESS,
  }
}

export const totpLoginError = error => {
  return {
    type: TOTP_LOGIN_ERROR,
    payload: { error },
  }
}

export const missingTotpError = (username, password) => {
  return {
    type: MISSING_TOTP_ERROR,
    payload: { username, password },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getMe = (history, destination) => {
  return {
    type: GET_ME,
    payload: { history, destination },
  }
}


export const getMeSuccess = (user) => {
  return {
    type: GET_ME_SUCCESS,
    payload: { user },
  }
}

export const getMeError = (payload) => {
  return {
    type: GET_ME_ERROR,
    payload,
  }
}
