import React, {useEffect} from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
    Col,
    Container, Row, Spinner,
} from "reactstrap"
import {useDispatch, useSelector} from "react-redux"
import {selectDashboard, selectDashboardError, selectDashboardLoading} from "../../store/dashboard/selectors"
import {loadDashboard} from "../../store/dashboard/actions";
import {withTranslation} from "react-i18next";
import WalletData from "./WalletData";
import Advisor from "./Advisor";

function Dashboard(props) {
    const dispatch = useDispatch()
    const dashboard = useSelector(selectDashboard)
    const loading = Boolean(useSelector(selectDashboardLoading))
    const error = useSelector(selectDashboardError)

    useEffect(() => {
        if (!dashboard && !error) {
            const timeout = setTimeout(() => {
                dispatch(loadDashboard())
            })

            return () => clearTimeout(timeout)
        }
    }, [dashboard, error])

    return (
      <div className="page-content">
        <MetaTags>
          <title>{props.t('Dashboard')} | Hesiode</title>
        </MetaTags>
        <Container fluid>
          <h4 className={"mb-4"}>{props.t('Dashboard')}</h4>
            {loading && (
                <>
                    <Spinner animation="border" style={{fontSize: '.5rem', width: '7rem', height: '7rem'}} />
                    <div className={"mt-3"}>
                        {props.t('LoadingInProgress')}...
                    </div>
                </>
            )}
            {Boolean(dashboard && !loading) && (
                <>
                    <Row>
                        <Col xs={12} xl={8}>
                            <WalletData />
                        </Col>
                        <Col xs={12} xl={4}>
                            <Advisor />
                        </Col>
                    </Row>
                </>
            )}
        </Container>
      </div>
    )
}

Dashboard.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Dashboard);
