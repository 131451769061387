import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import profile from "../../assets/images/profile-img.png";

function LoginFormHeader(props) {
    return (
        <div className="bg-primary bg-soft bg-soft-primary">
            <Row>
                <Col xs={7}>
                    <div className="text-primary p-4">
                        <h5 className="text-primary">{props.title || props.t('Login.WelcomeBack')}</h5>
                        <p>{props.subtitle || props.t('Login.SignInToContinue')}</p>
                    </div>
                </Col>
                <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                </Col>
            </Row>
        </div>
    )
}

LoginFormHeader.propTypes = {
    t: PropTypes.func,
    title: PropTypes.any,
    subtitle: PropTypes.any,
}

export default withTranslation()(LoginFormHeader);
