import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, Col, Input, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectTotpQrCode, selectTotpQrCodeError, selectTotpQrCodeLoading} from "../../store/auth/profile/selectors";
import {disableTotp, enableTotp, loadTotpQrCode} from "../../store/auth/profile/actions";
import {selectMe} from "../../store/auth/login/selectors";

function TwoFactorAuthentication(props) {
    const dispatch = useDispatch()
    const me = useSelector(selectMe)
    const qrCode = useSelector(selectTotpQrCode)
    const qrCodeLoading = useSelector(selectTotpQrCodeLoading)
    const qrCodeError = useSelector(selectTotpQrCodeError)
    const [activate, setActivate] = useState(false)
    const [activationCode, setActivationCode] = useState('')
    const active = Boolean(me?.otp?.activated_at)

    const handleSubmitEnableForm = event => {
        event.preventDefault()
        dispatch(enableTotp(activationCode))
        setActivationCode('')
    }

    const handleSubmitDisableForm = event => {
        event.preventDefault()
        dispatch(disableTotp(activationCode))
        setActivationCode('')
    }

    useEffect(() => {
        if (activate) {
            if (!active || me?.otp?.disabled_at) {
                dispatch(loadTotpQrCode())
            }
        }
    }, [activate, me])

    return <>
        <h4 className="card-title mb-4">{props.t('Profile.TwoFactorAuthentication')}</h4>
        <Card>
            <CardBody style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                {active && (
                    <form onSubmit={handleSubmitDisableForm} style={{display: "contents"}}>
                        <Alert color="info">{props.t('TwoFactorAuthentication.Active')}</Alert>
                        <div>
                            {props.t('TwoFactorAuthentication.DisableDescription')}
                        </div>
                        <Input
                            required
                            className="mt-3"
                            value={activationCode}
                            onChange={event => setActivationCode(event.target.value)}
                            placeholder={props.t('Form.Enter.TwoFactorAuthenticationCode')}
                        />
                        <Button type="submit" color="info" className="mt-3">
                            {props.t('TwoFactorAuthentication.Disable')}
                        </Button>
                    </form>
                )}
                {!active && activate && <>
                    {Boolean(qrCodeError) && <Alert color="danger">{props.t('TwoFactorAuthentication.QrCodeLoadingError')}</Alert>}
                    {Boolean(qrCodeLoading) && <Spinner size="xl" />}
                    {Boolean(qrCode) && (
                        <form onSubmit={handleSubmitEnableForm} style={{display: "contents"}}>
                            <img src={qrCode} alt={"TOTP QrCode"} style={{width: '400px'}} />
                            <div>
                                {props.t('TwoFactorAuthentication.QrCodeDescription')}
                            </div>
                            <Input
                                required
                                className="mt-3"
                                value={activationCode}
                                onChange={event => setActivationCode(event.target.value)}
                                placeholder={props.t('Form.Enter.TwoFactorAuthenticationCode')}
                            />
                            <Button type="submit" color="info" className="mt-3">
                                {props.t('TwoFactorAuthentication.Enable')}
                            </Button>
                        </form>
                    )}
                </>}
                {!active && !activate && <>
                    <Button color="info" onClick={() => setActivate(true)}>{props.t('TwoFactorAuthentication.Enable')}</Button>
                </>}
            </CardBody>
        </Card>
    </>
}

TwoFactorAuthentication.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(TwoFactorAuthentication);
