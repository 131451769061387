import React from 'react';
import PropTypes from 'prop-types';
import {Spinner} from "reactstrap";
import "./styles.scss";
import {withTranslation} from "react-i18next";

function Loading(props) {
    return (
        <div className="loading-page-container">
            <Spinner animation="border" className="loading-page-spinner"/>
            <span className="loading-page-text">{props.t('LoadingInProgress')}...</span>
        </div>
    )
}

Loading.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Loading);
