import axios from "axios"
import {del, get, getBlobAsBase64, patch, post, put} from "./api_helper"
import * as url from "./url_helper"
import {DASHBOARD} from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Edit profile
const patchMe = config => data => patch(url.PATCH_ME, data, config)
const patchMeAddress = config => data => patch(url.PATCH_ME_ADDRESS, data, config)

// OTP
const getTotpQrCode = config => () => getBlobAsBase64(url.GET_TOTP_QRCODE, config)
const postEnableTotp = config => data => post(url.POST_ENABLE_TOTP, data, config)


// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

/** PROJECT */
// add project
export const postWallet = config => project => post(url.ADD_NEW_WALLET, project, config)

// update project
export const patchWallet = config => (id, project) => put(url.UPDATE_WALLET.replaceAll('{id}', id), project, config)

// delete project
export const deleteWallet = config => id => del(url.UPDATE_WALLET.replaceAll('{id}', id), config)

// search project
export const postSearchWallets = config => data => post(url.SEARCH_WALLETS, data, config)

// get stripe settings
export const getStripeSettings = config => () => get(url.STRIPE_SETTINGS, config)
export const postStripeVerificationSessionSettings = config => () => post(url.STRIPE_VERIFICATION_SESSIONS, {}, config)

// get dashboard
export const getDashboard = config => () => get(url.DASHBOARD, config)

// get project details
export const getProjectsDetails = config => id =>
  get(url.GET_WALLET_DETAIL.replaceAll('{id}', id), { params: { id }, ...config})

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  patchMe,
  patchMeAddress,
  getTotpQrCode,
  postEnableTotp,
}
