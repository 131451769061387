import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";

function Processing(props) {
    return (
        <>
            <h2>{props.t('KYC.RequiresInput.YourIdentityIsManuallyVerified')}</h2>
            <p>{props.t('KYC.RequiresInput.PleaseComeBackLater')}</p>
        </>
    )
}

Processing.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Processing)
