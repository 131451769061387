export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"
export const MISSING_TOTP_ERROR = "MISSING_TOTP_ERROR"
export const TOTP_LOGIN = "TOTP_LOGIN"
export const TOTP_LOGIN_SUCCESS = "TOTP_LOGIN_SUCCESS"
export const TOTP_LOGIN_ERROR = "TOTP_LOGIN_ERROR"

export const GET_ME = "GET_ME"
export const GET_ME_SUCCESS = "GET_ME_SUCCESS"
export const GET_ME_ERROR = "GET_ME_ERROR"
