import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//projects
import projects from "./projects/reducer"

// user
import ChangeForgottenPassword from "./auth/changeforgetpwd/reducer";

// products
import Products from "./products/reducer";

// payment methods
import PaymentMethods from "./payment-methods/reducer";

// stripe
import stripe from "./stripe/reducer";

//projects
import dashboard from "./dashboard/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ChangeForgottenPassword,
  ForgetPassword,
  Profile,
  Products,
  projects,
  PaymentMethods,
  stripe,
  dashboard,
})

export default rootReducer
