import {
    CREATE_VERIFICATION_SESSION,
    CREATE_VERIFICATION_SESSION_FAIL,
    CREATE_VERIFICATION_SESSION_SUCCESS,
    GET_SETTINGS,
    GET_SETTINGS_FAIL,
    GET_SETTINGS_SUCCESS, VERIFICATION_SESSION_END
} from "./actionTypes";

const INIT_STATE = {
    settings: null,
    settingsLoading: false,
    settingsError: null,
    verificationSessionLoading: false,
    verificationSessionInProgress: false,
    verificationSessionError: null,
    verificationSessionSecret: null,
}

const stripe = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settingsLoading: true,
            }

        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings,
                settingsLoading: false,
            }

        case GET_SETTINGS_FAIL:
            return {
                ...state,
                settingsError: action.error,
                settingsLoading: false,
            }

        case CREATE_VERIFICATION_SESSION:
            return {
                ...state,
                verificationSessionLoading: true,
            }

        case CREATE_VERIFICATION_SESSION_SUCCESS:
            return {
                ...state,
                verificationSessionLoading: false,
                verificationSessionInProgress: true,
                verificationSessionSecret: action.secret,
            }

        case CREATE_VERIFICATION_SESSION_FAIL:
            return {
                ...state,
                verificationSessionLoading: false,
                verificationSessionError: action.error,
            }

        case VERIFICATION_SESSION_END:
            return {
                ...state,
                verificationSessionInProgress: false,
                verificationSessionSecret: null,
            }

        default:
            return state;
    }
}

export default stripe;
