import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";

function Verified(props) {
    return (
        <>
            <h2>{props.t('KYC.Verified.YourIdentityHasBeenVerified')}</h2>
            <p>GGWP t&apos;es un vrai</p>
        </>
    )
}

Verified.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Verified)
