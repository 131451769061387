import React from "react";

function CopyrightNotice() {
    return (
        <p>
            &copy; 2021 - {new Date().getFullYear()} Hesiode.
        </p>
    )
}

export default CopyrightNotice
