import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";

function Failed(props) {
    return (
        <>
            <h2>{props.t('KYC.Failed.AnErrorOccurred')}</h2>
            <h2>{props.t('KYC.Failed.YourIdentityCouldNotBeVerified')}</h2>
            <p>Mdr bouffon</p>
        </>
    )
}

Failed.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Failed)
