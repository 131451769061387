import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  CHANGE_CONTACT,
  CHANGE_CONTACT_SUCCESS,
  CHANGE_CONTACT_ERROR,
  LOAD_TOTP_QRCODE,
  LOAD_TOTP_QRCODE_SUCCESS,
  LOAD_TOTP_QRCODE_ERROR,
  CHANGE_CONTACT_FINISHED,
  UNLOAD_TOTP_QRCODE,
  ENABLE_TOTP,
  ENABLE_TOTP_SUCCESS,
  DISABLE_TOTP,
  DISABLE_TOTP_SUCCESS,
  DISABLE_TOTP_ERROR,
  ENABLE_TOTP_ERROR,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_SUCCESS,
  CHANGE_ADDRESS_ERROR,
  CHANGE_ADDRESS_FINISHED, CHANGE_CONTACT_RESET_SPECIFIC_ERRORS
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}


export const changeContact = contact => {
  return {
    type: CHANGE_CONTACT,
    payload: contact,
  }
}

export const changeContactSuccess = () => {
  return {
    type: CHANGE_CONTACT_SUCCESS,
  }
}


export const changeContactError = (error, specificErrors) => {
  return {
    type: CHANGE_CONTACT_ERROR,
    payload: { error, specificErrors },
  }
}



export const changeContactResetSpecificErrors = () => {
  return {
    type: CHANGE_CONTACT_RESET_SPECIFIC_ERRORS,
  }
}


export const changeContactFinished = () => {
  return {
    type: CHANGE_CONTACT_FINISHED,
  }
}


export const changeAddress = address => {
  return {
    type: CHANGE_ADDRESS,
    payload: address,
  }
}

export const changeAddressSuccess = () => {
  return {
    type: CHANGE_ADDRESS_SUCCESS,
  }
}


export const changeAddressError = error => {
  return {
    type: CHANGE_ADDRESS_ERROR,
    payload: error,
  }
}


export const changeAddressFinished = () => {
  return {
    type: CHANGE_ADDRESS_FINISHED,
  }
}

export const loadTotpQrCode = () => {
  return {
    type: LOAD_TOTP_QRCODE,
  }
}

export const loadTotpQrCodeSuccess = qrCode => {
  return {
    type: LOAD_TOTP_QRCODE_SUCCESS,
    payload: { qrCode },
  }
}

export const loadTotpQrCodeError = error => {
  return {
    type: LOAD_TOTP_QRCODE_ERROR,
    payload: { error },
  }
}


export const unloadTotpQrCode = () => {
  return {
    type: UNLOAD_TOTP_QRCODE,
  }
}


export const enableTotp = code => {
  return {
    type: ENABLE_TOTP,
    payload: { code },
  }
}


export const enableTotpSuccess = () => {
  return {
    type: ENABLE_TOTP_SUCCESS,
  }
}

export const enableTotpError = error => {
  return {
    type: ENABLE_TOTP_ERROR,
    payload: { error },
  }
}


export const disableTotp = code => {
  return {
    type: DISABLE_TOTP,
    payload: { code },
  }
}


export const disableTotpSuccess = () => {
  return {
    type: DISABLE_TOTP_SUCCESS,
  }
}

export const disableTotpError = error => {
  return {
    type: DISABLE_TOTP_ERROR,
    payload: { error },
  }
}
