import {LOAD_DASHBOARD, LOAD_DASHBOARD_FAIL, LOAD_DASHBOARD_SUCCESS} from "./actionTypes";

const initialState = {
    dashboard: null,
    loading: false,
    error: null,
}

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case LOAD_DASHBOARD:
            return {...state, loading: true, error: null}

        case LOAD_DASHBOARD_SUCCESS:
            return {...state, loading: false, dashboard: action.dashboard}

        case LOAD_DASHBOARD_FAIL:
            return {...state, loading: false, error: action.error}

        default:
            return state
    }
}
