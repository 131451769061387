export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"

export const CHANGE_CONTACT = 'CHANGE_CONTACT'
export const CHANGE_CONTACT_SUCCESS = 'CHANGE_CONTACT_SUCCESS'
export const CHANGE_CONTACT_ERROR = 'CHANGE_CONTACT_ERROR'
export const CHANGE_CONTACT_RESET_SPECIFIC_ERRORS = 'CHANGE_CONTACT_RESET_SPECIFIC_ERRORS'
export const CHANGE_CONTACT_FINISHED = 'CHANGE_CONTACT_FINISHED'

export const CHANGE_ADDRESS = 'CHANGE_ADDRESS'
export const CHANGE_ADDRESS_SUCCESS = 'CHANGE_ADDRESS_SUCCESS'
export const CHANGE_ADDRESS_ERROR = 'CHANGE_ADDRESS_ERROR'
export const CHANGE_ADDRESS_FINISHED = 'CHANGE_ADDRESS_FINISHED'

export const LOAD_TOTP_QRCODE = 'LOAD_TOTP_QRCODE'
export const LOAD_TOTP_QRCODE_SUCCESS = 'LOAD_TOTP_QRCODE_SUCCESS'
export const LOAD_TOTP_QRCODE_ERROR = 'LOAD_TOTP_QRCODE_ERROR'
export const UNLOAD_TOTP_QRCODE = 'UNLOAD_TOTP_QRCODE'

export const ENABLE_TOTP = 'ENABLE_TOTP'
export const ENABLE_TOTP_SUCCESS = 'ENABLE_TOTP_SUCCESS'
export const ENABLE_TOTP_ERROR = 'ENABLE_TOTP_ERROR'

export const DISABLE_TOTP = 'DISABLE_TOTP'
export const DISABLE_TOTP_SUCCESS = 'DISABLE_TOTP_SUCCESS'
export const DISABLE_TOTP_ERROR = 'DISABLE_TOTP_ERROR'
