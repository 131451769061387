import {call, put, select, takeEvery} from "redux-saga/effects";
import {selectAccessToken} from "../auth/login/selectors";
import {getStripeSettings, postStripeVerificationSessionSettings} from "../../helpers/fakebackend_helper";
import {
    createStripeVerificationSessionFail,
    createStripeVerificationSessionSuccess,
    getStripeSettingsFail,
    getStripeSettingsSuccess
} from "./actions";
import {CREATE_VERIFICATION_SESSION, GET_SETTINGS} from "./actionTypes";

function* fetchStripeSettings() {
    const accessToken = yield select(selectAccessToken);

    try {
        const response = yield call(getStripeSettings({headers: {Authorization: `Bearer ${accessToken}`}}))
        yield put(getStripeSettingsSuccess(response))
    } catch (error) {
        console.error(error);
        yield put(getStripeSettingsFail(error))
    }
}

function* createStripeVerificationSession() {
    const accessToken = yield select(selectAccessToken);

    try {
        const response = yield call(postStripeVerificationSessionSettings({headers: {Authorization: `Bearer ${accessToken}`}}))
        yield put(createStripeVerificationSessionSuccess(response.secret))
    } catch (error) {
        yield put(createStripeVerificationSessionFail(error))
    }
}

function* stripeSaga() {
    yield takeEvery(GET_SETTINGS, fetchStripeSettings);
    yield takeEvery(CREATE_VERIFICATION_SESSION, createStripeVerificationSession);
}

export default stripeSaga
