/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCTS DETAIL*/
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
 export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
 export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
 export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
 export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_PRODUCT = "DELETE_PRODUCT"
 export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
 export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"
