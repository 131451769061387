import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {selectDashboard} from "../../store/dashboard/selectors"
import {Card, CardBody, Col, Row} from "reactstrap"
import {Line} from "react-chartjs-2"

function WalletData(props) {
    const dashboard = useSelector(selectDashboard)
    const total = parseFloat(dashboard.total_income) - parseFloat(dashboard.total_withdrawal)
    const totalStart = total + dashboard.evolution.reduce((acc, item) => acc + parseFloat(item.value), 0)

    return (
        <>
            <Row>
                <Col xs={4}>
                    <Card className={"mini-stats-wid"}>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">{props.t('Dashboard.WalletData.NumberWallets')}</p>
                                    <h4 className="mb-0">{dashboard.wallets}</h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                    <span className="avatar-title">
                                        <i className="bx bx-wallet font-size-24"/>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card className={"mini-stats-wid"}>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">{props.t('Dashboard.WalletData.TotalValue')}</p>
                                    <h4 className="mb-0">{total.toFixed(dashboard.payment_plan?.currency?.digits || 2)} {dashboard.payment_plan?.currency?.symbol || '€'}</h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                    <span className="avatar-title">
                                        <i className="bx bx-money font-size-24"/>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={4}>
                    <Card className={"mini-stats-wid"}>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">{props.t('Dashboard.WalletData.NextDebit')}</p>
                                    <h4 className="mb-0">{dashboard.payment_plan?.amount || 0} {dashboard.payment_plan?.currency?.symbol || '€'}</h4>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                    <span className="avatar-title">
                                        <i className="mdi mdi-clock font-size-24"/>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <div className="mb-4 card-title">{props.t('Dashboard.WalletData.Evolution')}</div>
                    <Line
                        data={{
                            labels: dashboard.evolution.map(point => point.date),
                            // labels: ['', '', '', '', '', '', '', '', '', '', ''],
                            datasets: [
                                {
                                    label: '',
                                    fill: true,
                                    lineTension: 0.5,
                                    backgroundColor: "rgba(85, 110, 230, 0.2)",
                                    borderColor: "#556ee6",
                                    borderCapStyle: "butt",
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: "miter",
                                    pointBorderColor: "#556ee6",
                                    pointBackgroundColor: "#fff",
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: "#556ee6",
                                    pointHoverBorderColor: "#fff",
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: dashboard.evolution.map(point => totalStart + parseFloat(point.value)),
                                    // data: [15, 28, 30, -30, 35, -22, 20, 110, 23, 32, 4],
                                }
                            ]
                        }}
                        options={{
                            legend: {
                                display: false
                            },
                        }}
                    />
                </CardBody>
            </Card>
        </>
    )
}

WalletData.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(WalletData)
