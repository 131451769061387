import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Cards
import PaymentMethodCards from "./PaymentMethodCards"

import { getPaymentMethods } from "store/actions"
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class PaymentMethodsGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      totalPage: 5, //replace this with total pages of data
    }
  }

  componentDidMount() {
    const { onGetPaymentMethods } = this.props
    onGetPaymentMethods()
  }

  handlePageClick = page => {
    this.setState({ page })
  }

  render() {
    const { paymentMethods } = this.props
    const { page, totalPage } = this.state

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>PaymentMethods Grid | Hesiode</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("PaymentMethods")} breadcrumbItem={this.props.t("PaymentMethods List")} />

            <Row>
              {/* Import Cards */}
              <PaymentMethodCards paymentMethods={paymentMethods} key={"cardproject"} />
            </Row>

            <Row>
              <Col lg="12">
                <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={() => this.handlePageClick(page - 1)}
                    />
                  </PaginationItem>
                  {map(Array(totalPage), (item, i) => (
                    <PaginationItem active={i + 1 === page} key={'_k'+i}>
                      <PaginationLink
                        onClick={() => this.handlePageClick(i + 1)}
                        href="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={page === totalPage}>
                    <PaginationLink
                      next
                      href="#"
                      onClick={() => this.handlePageClick(page + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PaymentMethodsGrid.propTypes = {
  paymentMethods: PropTypes.array,
  onGetPaymentMethods: PropTypes.func,
  t: PropTypes.func,
}

const mapStateToProps = ({ PaymentMethods }) => ({
  paymentMethods: PaymentMethods.paymentMethods,
})

const mapDispatchToProps = dispatch => ({
  onGetPaymentMethods: () => dispatch(getPaymentMethods()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(),
)(PaymentMethodsGrid)
