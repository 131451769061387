import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import MetaTags from "react-meta-tags";
import {Container} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import {useSelector} from "react-redux";
import {selectMe} from "../../store/auth/login/selectors";
import VerificationSession from "./VerificationSession";
import Loading from "../Loading";
import Failed from "./Failed";
import Verified from "./Verified";
import Processing from "./Processing";
import RequiresInput from "./RequiresInput";

const STATUS_COMPONENT = {
    'none': VerificationSession,
    'inited': VerificationSession,
    'created': VerificationSession,
    'processing': Processing,
    'requires_input': RequiresInput,
    'failed': Failed,
    'verified': Verified,
}

function Kyc() {
    const me = useSelector(selectMe)

    const Page = STATUS_COMPONENT[me?.validation_status] ?? Loading;

    return (
        <div className="page-content">
            <MetaTags>
                <title>KYC | Hesiode</title>
            </MetaTags>
            <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumb title="Hesiode" breadcrumbItem="KYC" />

                <Page />
            </Container>
        </div>
    )
}

Kyc.propTypes = {

};

export default withTranslation()(Kyc);
