/* SETTINGS */
export const GET_SETTINGS = "GET_STRIPE_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_STRIPE_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_STRIPE_SETTINGS_FAIL"

/* CREATE_VERIFICATION_SESSION */
export const CREATE_VERIFICATION_SESSION = "CREATE_VERIFICATION_SESSION"
export const CREATE_VERIFICATION_SESSION_SUCCESS = "CREATE_VERIFICATION_SESSION_SUCCESS"
export const CREATE_VERIFICATION_SESSION_FAIL = "CREATE_VERIFICATION_SESSION_FAIL"
export const VERIFICATION_SESSION_END = "VERIFICATION_SESSION_END"
