import { del, get, post, put, patch } from "./api_helper"
import * as url from "./url_helper"
import {PUT_ME_EMAIL} from "./url_helper";

// Login Method
export const authenticate = data => post(url.POST_AUTHENTICATE, data, {
    errorMessage: 'Error.Api.InvalidCredentials',
})
export const forgottenPassword = data => post(url.POST_FORGOTTEN_PASSWORD, data, {
    errorMessage: 'Error.Api.InvalidUsername',
})
export const changeForgottenPassword = data => post(url.POST_CHANGE_FORGOTTEN_PASSWORD, data, {
    errorMessage: 'Error.Api.InvalidPassword',
})

// Me
export const me = (token) => get(url.ME, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
    errorMessage: 'Error.Api.InvalidCredentials',
})
export const changeEmail = (token, data) => put(url.PUT_ME_EMAIL, data, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
    errorMessage: 'Error.Api.ChangeEmailError',
})
export const changeAddress = (token, data) => patch(url.PATCH_ME_ADDRESS, data, {
    headers: {
        Authorization: `Bearer ${token}`,
    },
    errorMessage: 'Error.Api.ChangeAddressError',
})
