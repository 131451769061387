import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import Profile from "../pages/Profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangeForgetPassword from "../pages/Authentication/ChangeForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import WalletsCreate from "../pages/Wallets/WalletCreate";
import WalletsGrid from "../pages/Wallets/WalletsGrid";
import CreatePaymentMethod from "../pages/PaymentMethods/CreatePaymentMethod";
import PaymentMethodsGrid from "../pages/PaymentMethods/PaymentMethodsGrid";

// KYC
import Kyc from "../pages/Kyc";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // profile
  { path: "/profile", component: Profile },

  // profile
  { path: "/projects", component: WalletsGrid },
  { path: "/projects/new", component: WalletsCreate },

  // payment methods
  { path: "/payment_methods", component: PaymentMethodsGrid },
  { path: "/payment_methods/new", component: CreatePaymentMethod },

  // kyc
  { path: "/kyc", component: Kyc},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-forgotten-password", component: ChangeForgetPassword },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
