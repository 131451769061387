import {
  CHANGE_FORGOTTEN_PASSWORD,
  CHANGE_FORGOTTEN_PASSWORD_SUCCESS,
  CHANGE_FORGOTTEN_PASSWORD_ERROR,
} from "./actionTypes"
import {LOGIN_SUCCESS} from "../login/actionTypes";

const initialState = {
  success: false,
  loading: false,
  error: null,
}

const ChangeForgottenPassword = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      state = {
        ...state,
        success: false,
        loading: false,
        error: null,
      }
      break
    case CHANGE_FORGOTTEN_PASSWORD:
      state = {
        ...state,
        success: false,
        loading: true,
        error: null,
      }
      break
    case CHANGE_FORGOTTEN_PASSWORD_SUCCESS:
      state = {
        ...state,
        success: true,
        loading: false,
        error: null,
      }
      break
    case CHANGE_FORGOTTEN_PASSWORD_ERROR:
      state = {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      }
      break
  }
  return state
}

export default ChangeForgottenPassword
