import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {Alert, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectTotpLogin, selectTotpLoginError, selectTotpLoginLoading} from "../../store/auth/login/selectors";
import {totpLogin} from "../../store/auth/login/actions";
import {useHistory} from "react-router-dom";

function TotpLoginModal(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const open = useSelector(selectTotpLogin)
    const loading = useSelector(selectTotpLoginLoading)
    const error = useSelector(selectTotpLoginError)
    const [authenticationCode, setAuthenticationCode] = useState('');
    const inputRef = useRef();

    const handleSubmit = event => {
        event.preventDefault()
        dispatch(totpLogin(authenticationCode, history))
    }

    return (
        <Modal isOpen={open}>
            <form onSubmit={handleSubmit}>
                <ModalHeader>
                    {props.t("TotpLoginModal.Header")}
                </ModalHeader>
                <ModalBody>
                    {props.t("TotpLoginModal.Body")}

                    {Boolean(error) && <Alert color="danger">{props.t('TotpLoginModal.Error')}</Alert>}

                    <Input
                        required
                        className="mt-3"
                        value={authenticationCode}
                        onChange={event => setAuthenticationCode(event.target.value)}
                        placeholder={props.t('Form.Enter.TwoFactorAuthenticationCode')}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="info">
                        {loading ?
                            <Spinner size="sm" /> :
                            props.t('Login.LogIn')
                        }
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}

TotpLoginModal.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(TotpLoginModal);
