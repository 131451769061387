import {
  CHANGE_FORGOTTEN_PASSWORD,
  CHANGE_FORGOTTEN_PASSWORD_SUCCESS,
  CHANGE_FORGOTTEN_PASSWORD_ERROR,
} from "./actionTypes"

export const changeForgottenPassword = (values, history) => {
  return {
    type: CHANGE_FORGOTTEN_PASSWORD,
    payload: { values, history },
  }
}

export const changeForgottenPasswordSuccess = () => {
  return {
    type: CHANGE_FORGOTTEN_PASSWORD_SUCCESS,
    payload: { },
  }
}

export const changeForgottenPasswordError = message => {
  return {
    type: CHANGE_FORGOTTEN_PASSWORD_ERROR,
    payload: message,
  }
}
