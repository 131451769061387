import {LOAD_DASHBOARD, LOAD_DASHBOARD_FAIL, LOAD_DASHBOARD_SUCCESS} from "./actionTypes";

export function loadDashboard() {
    return {
        type: LOAD_DASHBOARD,
    }
}

export function loadDashboardSuccess(dashboard) {
    return {
        type: LOAD_DASHBOARD_SUCCESS,
        dashboard,
    }
}

export function loadDashboardFail(error) {
    return {
        type: LOAD_DASHBOARD_FAIL,
        error,
    }
}
