import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {useSelector} from "react-redux";
import {selectMe} from "../../store/auth/login/selectors";
import {Card, CardBody, Col, Row} from "reactstrap";
import profileImage from "../../assets/images/profile-img.png"
import avatar from "../../assets/images/users/avatar-2.jpg"

function Advisor(props) {
    const me = useSelector(selectMe)

    if (!me.owner) {
        return null
    }

    return (
        <Row>
            <Col xs={12}>
                <Card className={"overflow-hidden"}>
                    <div className="bg-primary bg-soft">
                        <div className="row">
                            <div className="col-7">
                                <div className="text-primary p-3">
                                    <h5 className="text-primary">{props.t('Dashboard.Manager.YourAdvisor')}</h5>
                                    <p>{me.owner.company}</p>
                                </div>
                            </div>
                            <div className="align-self-end col-5">
                                <img src={profileImage} className="img-fluid" alt="advisor profile" />
                            </div>
                        </div>
                    </div>
                    <CardBody className={"pt-0"}>
                        <div className="avatar-md profile-user-wid mb-4">
                            <img
                                src={avatar}
                                alt="avatar"
                                className="img-thumbnail rounded-circle"
                            />
                        </div>
                        <Row>
                            <Col xs={4}>
                                <h5 className="font-size-15 text-truncate">{me.owner.first_name} {me.owner.last_name}</h5>
                                <p className="text-muted mb-0 text-truncate">{me.owner.job_title}</p>
                            </Col>
                            <Col xs={4}>
                                <h5 className="font-size-15">{props.t('Phone')}</h5>
                                <p className="text-muted mb-0">{me.owner.formatted_phone}</p>
                            </Col>
                            <Col xs={4}>
                                <h5 className="font-size-15">{props.t('Email')}</h5>
                                <p className="text-muted mb-0">{me.owner.email}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

Advisor.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(Advisor)
