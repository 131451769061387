import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import { addNewProject, getProjects } from "../../store/projects/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ProductSelect from "../../components/ProductSelect";

class WalletCreate extends Component {
  constructor() {
    super();
    this.state = {
      endDate: new Date(),
      projects: [],
      project: "",
    };
    this.endDateChange.bind(this);
  }

  endDateChange = date => {
    this.setState({
      endDate: date,
    });
  };

  render() {
    const { projects } = this.props;
    const project = this.state.project;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t('Projects.NewProject')} | Hesiode</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t('Projects')} breadcrumbItem={this.props.t('Projects.NewProject')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{this.props.t('Projects.StartNewProject')}</CardTitle>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        product: (project?.product) || "",
                        name: (project?.name) || "",
                        description: (project?.description) || "",
                        endsAt: (project?.endsAt) || "",
                        amountPerPayment: (project?.amountPerPayment) || 20,
                        paymentRecurrence: (project?.paymentRecurrence) || "weekly",
                      }}
                      validationSchema={Yup.object().shape({
                        product: Yup.string().required("Please Enter Your Product"),
                        name: Yup.string().required("Please Enter Your Project Name"),
                        description: Yup.string().required(
                          "Please Enter Your Description"
                        ),
                        endsAt: Yup.string().required(
                          "Please Enter Your Due Date"
                        ),
                        paymentRecurrence: Yup.string().required("Please Enter Your Budget"),
                      })}
                      onSubmit={values => {
                        console.log(values, "values");
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <React.Fragment>
                          <Form>
                            <Row className="mb-4">
                              <Label
                                htmlFor="product"
                                className="col-form-label col-lg-2"
                              >
                                {this.props.t('Product')}
                              </Label>
                              <Col lg="10">
                                <Field
                                  name="product"
                                  as={ProductSelect}
                                  className={
                                    "form-control" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="name"
                                className="col-form-label col-lg-2"
                              >
                                {this.props.t('Name')}
                              </Label>
                              <Col lg="10">
                                <Field
                                  name="name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                htmlFor="description"
                                className="col-form-label col-lg-2"
                              >
                                {this.props.t('Description')}
                              </Label>
                              <Col lg="10">
                                <Field
                                  name="description"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.description && touched.description
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                htmlFor="endsAt"
                                className="col-form-label col-lg-2"
                              >
                                {this.props.t('Projects.CreateForm.EndsAt')}
                              </Label>
                              <Col lg="10">
                                <Field
                                  name="endsAt"
                                  type="date"
                                  className={
                                    "form-control" +
                                    (errors.endsAt && touched.endsAt
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="endsAt"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="paymentRecurrence"
                                className="col-form-label col-lg-2"
                              >
                                {this.props.t('Projects.CreateForm.AmountPerPayment')}
                              </Label>
                              <Col lg="10">
                                <Field
                                  name="amountPerPayment"
                                  type="number"
                                  step="0.01"
                                  className={
                                      "form-control" +
                                      (errors.amountPerPayment &&
                                      touched.amountPerPayment
                                          ? " is-invalid"
                                          : "")
                                  }
                                />
                                <ErrorMessage
                                  name="amountPerPayment"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="paymentRecurrence"
                                className="col-form-label col-lg-2"
                              >
                                {this.props.t('Projects.CreateForm.PaymentRecurrence')}
                              </Label>
                              <Col lg="10">
                                <Field
                                  name="paymentRecurrence"
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.paymentRecurrence &&
                                    touched.paymentRecurrence
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value="weekly">{this.props.t('Projects.CreateForm.PaymentRecurrence.Weekly')}</option>
                                  <option value="twice_a_month">{this.props.t('Projects.CreateForm.PaymentRecurrence.TwiceAMonth')}</option>
                                  <option value="monthly">{this.props.t('Projects.CreateForm.PaymentRecurrence.Monthly')}</option>
                                </Field>
                                <ErrorMessage
                                  name="paymentRecurrence"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <Row className="justify-content-end">
                              <Col lg="10">
                                <Button type="submit" color="primary">
                                  {this.props.t('Projects.CreateForm.Submit')}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </React.Fragment>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

WalletCreate.propTypes = {
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onAddNewProject: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
});

const mapDispatchToProps = dispatch => ({
  onGetProjects: () => dispatch(getProjects()),
  onAddNewProject: project => dispatch(addNewProject(project)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(),
)(WalletCreate);
