/* PAYMENT_METHODS */
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS"
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL"

/* PAYMENT_METHODS DETAIL*/
export const GET_PAYMENT_METHOD_DETAIL = "GET_PAYMENT_METHOD_DETAIL"
export const GET_PAYMENT_METHOD_DETAIL_SUCCESS = "GET_PAYMENT_METHOD_DETAIL_SUCCESS"
export const GET_PAYMENT_METHOD_DETAIL_FAIL = "GET_PAYMENT_METHOD_DETAIL_FAIL"

/**
 * add user
 */
 export const ADD_NEW_PAYMENT_METHOD = "ADD_NEW_PAYMENT_METHOD"
 export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS"
 export const ADD_PAYMENT_METHOD_FAIL = "ADD_PAYMENT_METHOD_FAIL"
 
 /**
  * Edit user
  */
 export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD"
 export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS"
 export const UPDATE_PAYMENT_METHOD_FAIL = "UPDATE_PAYMENT_METHOD_FAIL"
 
 /**
  * Delete user
  */
 export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD"
 export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS"
 export const DELETE_PAYMENT_METHOD_FAIL = "DELETE_PAYMENT_METHOD_FAIL"
