import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {forgottenPassword} from "../../../helpers/backend_helper";

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { values, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, values.email)
      if (response) {
        yield put(userForgetPasswordSuccess("ForgotPassword.SuccessMessage"))
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      yield call(forgottenPassword, {
        username: values.email,
      })

      yield put(userForgetPasswordSuccess("ForgotPassword.SuccessMessage"))
    }
  } catch (error) {
    yield put(userForgetPasswordError(error.message))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
