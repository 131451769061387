//LOGIN
export const POST_AUTHENTICATE = process.env.REACT_APP_API_URL + "/api/v1/authenticate"
export const POST_FORGOTTEN_PASSWORD = process.env.REACT_APP_API_URL + "/api/v1/public/forgotten_password";
export const POST_CHANGE_FORGOTTEN_PASSWORD = process.env.REACT_APP_API_URL + "/api/v1/public/change_forgotten_password";
export const PUT_ME_EMAIL = process.env.REACT_APP_API_URL + "/api/v1/me/email";

// ME
export const ME = process.env.REACT_APP_API_URL + "/api/v1/me";

//PROFILE
export const PATCH_ME = process.env.REACT_APP_API_URL + "/api/v1/me"
export const PATCH_ME_ADDRESS = process.env.REACT_APP_API_URL + "/api/v1/me/address"

// Totp
export const GET_TOTP_QRCODE = process.env.REACT_APP_API_URL + '/api/v1/totp/qrcode'
export const POST_ENABLE_TOTP = process.env.REACT_APP_API_URL + '/api/v1/totp/enable'

//WALLETS
export const SEARCH_WALLETS = process.env.REACT_APP_API_URL + "/api/v1/wallets/search"
export const GET_WALLET_DETAIL = process.env.REACT_APP_API_URL + "/api/v1/wallets/{id}"
export const ADD_NEW_WALLET = process.env.REACT_APP_API_URL + "/api/v1/wallets"
export const UPDATE_WALLET = process.env.REACT_APP_API_URL + "/api/v1/wallets/{id}"
export const DELETE_WALLET = process.env.REACT_APP_API_URL + "/api/v1/wallets/{id}"

// Stripe
export const STRIPE_SETTINGS = process.env.REACT_APP_API_URL + "/api/v1/stripe/settings"
export const STRIPE_VERIFICATION_SESSIONS = process.env.REACT_APP_API_URL + "/api/v1/stripe/verification_sessions"

// Dashboard
export const DASHBOARD = process.env.REACT_APP_API_URL + "/api/v1/dashboard"
