import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  CHANGE_CONTACT,
  CHANGE_CONTACT_SUCCESS,
  CHANGE_CONTACT_ERROR,
  LOAD_TOTP_QRCODE,
  LOAD_TOTP_QRCODE_SUCCESS,
  LOAD_TOTP_QRCODE_ERROR,
  CHANGE_CONTACT_FINISHED,
  UNLOAD_TOTP_QRCODE,
  ENABLE_TOTP_SUCCESS,
  ENABLE_TOTP_ERROR,
  ENABLE_TOTP,
  DISABLE_TOTP,
  DISABLE_TOTP_SUCCESS,
  DISABLE_TOTP_ERROR,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_SUCCESS,
  CHANGE_ADDRESS_ERROR,
  CHANGE_ADDRESS_FINISHED, CHANGE_CONTACT_RESET_SPECIFIC_ERRORS
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  edited: false,
  loading: false,
  changeContactError: null,
  changeContactSpecificErrors: null,
  addressEdited: false,
  addressLoading: false,
  addressError: false,
  totpQrcode: null,
  totpQrcodeLoading: false,
  totpQrcodeError: null,
  totpEnableLoading: false,
  totpEnableError: null,
  totpDisableLoading: false,
  totpDisableError: null,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG :
      state = { ...state, success: null }
      break
    case CHANGE_CONTACT:
      state = { ...state, edited: false, loading: true, changeContactError: null, changeContactSpecificErrors: null}
      break
    case CHANGE_CONTACT_SUCCESS:
      state = { ...state, edited: true, loading: false, changeContactError: null, changeContactSpecificErrors: null}
      break
    case CHANGE_CONTACT_ERROR:
      state = { ...state, edited: false, loading: false, changeContactError: action.payload.error, changeContactSpecificErrors: action.payload.specificErrors}
      break
    case CHANGE_CONTACT_RESET_SPECIFIC_ERRORS:
      state = { ...state, changeContactError: null, changeContactSpecificErrors: null}
      break
    case CHANGE_CONTACT_FINISHED:
      state = { ...state, edited: false, loading: false, changeContactError: null, changeContactSpecificErrors: null}
      break
    case CHANGE_ADDRESS:
      state = { ...state, addressEdited: false, addressLoading: true, addressError: null}
      break
    case CHANGE_ADDRESS_SUCCESS:
      state = { ...state, addressEdited: true, addressLoading: false, addressError: null}
      break
    case CHANGE_ADDRESS_ERROR:
      state = { ...state, addressEdited: false, addressLoading: false, addressError: action.payload}
      break
    case CHANGE_ADDRESS_FINISHED:
      state = { ...state, addressEdited: false, addressLoading: false, addressError: null}
      break
    case LOAD_TOTP_QRCODE:
      state = { ...state, totpQrcode: null, totpQrcodeLoading: true, totpQrcodeError: null}
      break
    case LOAD_TOTP_QRCODE_SUCCESS:
      state = { ...state, totpQrcode: action.payload.qrCode, totpQrcodeLoading: false, totpQrcodeError: null}
      break
    case LOAD_TOTP_QRCODE_ERROR:
      state = { ...state, totpQrcode: null, totpQrcodeLoading: false, totpQrcodeError: action.payload.error}
      break
    case UNLOAD_TOTP_QRCODE:
      state = { ...state, totpQrcode: null, totpQrcodeLoading: false, totpQrcodeError: null}
      break
    case ENABLE_TOTP:
      state = { ...state, totpEnableLoading: true, totpEnableError: null}
      break
    case ENABLE_TOTP_SUCCESS:
      state = { ...state, totpEnableLoading: false, totpEnableError: null}
      break
    case ENABLE_TOTP_ERROR:
      state = { ...state, totpEnableLoading: false, totpEnableError: action.payload.error}
      break
    case DISABLE_TOTP:
      state = { ...state, totpDisableLoading: true, totpDisableError: null}
      break
    case DISABLE_TOTP_SUCCESS:
      state = { ...state, totpDisableLoading: false, totpDisableError: null}
      break
    case DISABLE_TOTP_ERROR:
      state = { ...state, totpDisableLoading: false, totpDisableError: action.payload.error}
      break
  }
  return state
}

export default profile
