import {call, put, select, takeEvery} from "redux-saga/effects";
import {LOAD_DASHBOARD} from "./actionTypes";
import {selectAccessToken} from "../auth/login/selectors";
import {loadDashboardFail, loadDashboardSuccess} from "./actions";
import {getDashboard} from "../../helpers/fakebackend_helper";

function* fetchDashboard() {
    const accessToken = yield select(selectAccessToken)

    try {
        const dashboard = yield call(getDashboard({headers: {Authorization: `Bearer ${accessToken}`}}))
        yield put(loadDashboardSuccess(dashboard))
    } catch (error) {
        yield put(loadDashboardFail(error))
    }
}

function* dashboardSaga() {
    yield takeEvery(LOAD_DASHBOARD, fetchDashboard);
}

export default dashboardSaga
